import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* raleway-regular - latin_cyrillic */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('/fonts/raleway-v17-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/raleway-v17-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-700 - latin_cyrillic */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('/fonts/raleway-v17-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/raleway-v17-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* playfair-display-regular - latin_cyrillic */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('/fonts/playfair-display-v21-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v21-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-700 - latin_cyrillic */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('/fonts/playfair-display-v21-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v21-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  html, body {
    min-height: 100%;
  }

  html {
    background: var(--background-color);
    color: var(--text-color);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-default);
    line-height: var(--line-height-body);
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-secondary);
    line-height: var(--line-height-heading);
    letter-spacing: 0.05em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .fs-1 {
    font-size: var(--font-size-1);
  }

  .fs-2 {
    font-size: var(--font-size-2);
  }

  .fs-3 {
    font-size: var(--font-size-3);
  }

  .fs-4 {
    font-size: var(--font-size-4);
  }

  .fs-5 {
    font-size: var(--font-size-5);
  }

  .relative {
    position: relative;
  }
`

export default React.memo(GlobalStyles)

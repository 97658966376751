import React from "react"
import { createGlobalStyle } from "styled-components"

const CSSVariables = createGlobalStyle`
  :root {

    --color-white: #fff;
    --color-black: #000;
    --color-whitesmoke: #f5f5f5;
    --color-mistyrose: #ffe4e1;
    --color-silver: #f2f2f2;
    --color-shiny: #E9F3EE;

    --background-color: var(--color-white);
    --text-color: var(--color-black);

    --font-family-primary: 'Raleway', sans-serif;
    --font-family-secondary: 'Playfair Display', serif;

    --font-size-1: 12px;
    --font-size-2: 14px;
    --font-size-3: 16px;
    --font-size-4: 20px;
    --font-size-5: 24px;
    --font-size-6: 36px;
    --font-size-7: 48px;
    --font-size-8: 72px;

    --font-size-default: var(--font-size-3);

    --line-height-body: 1.5;
    --line-height-heading: 1.25;
    --line-height-navigation: 1.5;

    --navbar-z-index: 999;
    --navbar-background-color: var(--color-white);
  }
`

export default React.memo(CSSVariables)
